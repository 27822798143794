<template>
  <div class="teaching">
    <div class="content1" />
    <div class="title">录播+直播 全场景模式教学</div>
    <div class="list1 list">
      <div class="list1-item">
        <img class="layIcon" src="@/style/image/teachIng/icon1.png">
        <div class="item-content">
          <div class="content-left">
            <div class="text">精讲网课</div>
            <div class="text">巩固基础</div>
            <div class="line" />
          </div>
          <div class="content-right">
            <div class="text">
              <div class="yuan" />
              科目导学
            </div>
            <div class="text">
              <div class="yuan" />
              课程精讲
            </div>
            <div class="text">
              <div class="yuan" />
              知识剖析
            </div>
          </div>
        </div>
      </div>
      <div class="list1-item">
        <img class="layIcon" src="@/style/image/teachIng/icon2.png">
        <div class="item-content">
          <div class="content-left">
            <div class="text">串讲课程</div>
            <div class="text">强化学习</div>
            <div class="line" />
          </div>
          <div class="content-right">
            <div class="text">
              <div class="yuan" />
              基础巩固
            </div>
            <div class="text">
              <div class="yuan" />
              重点强化
            </div>
            <div class="text">
              <div class="yuan" />
              直播授课
            </div>
          </div>
        </div>
      </div>
      <div class="list1-item">
        <img class="layIcon" src="@/style/image/teachIng/icon3.png">
        <div class="item-content">
          <div class="content-left">
            <div class="text">高清直播</div>
            <div class="text">在线互动</div>
            <div class="line" />
          </div>
          <div class="content-right">
            <div class="text">
              <div class="yuan" />
              毫秒级延迟
            </div>
            <div class="text">
              <div class="yuan" />
              在线答疑
            </div>
            <div class="text">
              <div class="yuan" />
              知识点归纳
            </div>
          </div>
        </div>
      </div>
      <div class="list1-item">
        <img class="layIcon" src="@/style/image/teachIng/icon4.png">
        <div class="item-content">
          <div class="content-left">
            <div class="text">冲刺课程</div>
            <div class="text">突破提升</div>
            <div class="line" />
          </div>
          <div class="content-right">
            <div class="text">
              <div class="yuan" />
              考前串讲
            </div>
            <div class="text">
              <div class="yuan" />
              聚题库刷题
            </div>
            <div class="text">
              <div class="yuan" />
              手把手做题
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="title" style="margin-top: 100px">
      梯进式课程模块 分层分阶段教学
    </div>
    <div class="list2 list">
      <div class="list2-item">
        <div class="item-title">梯进式课程设置</div>
        <div class="content">
          <div class="text">知识点精讲+章节习题</div>
          <div class="text">集训学习+刷题+解惑</div>
          <div class="text">知识串讲+习题训练</div>
          <div class="text">知识点攻克+重难点攻克</div>
        </div>
      </div>
      <div class="list2-item">
        <div class="item-title">分层分阶段学习</div>
        <div class="content">
          <div class="text">系统学习 夯实基础</div>
          <div class="text">要点归纳 强化训练</div>
          <div class="text">知识浓缩 突击难点</div>
          <div class="text">提炼重点 助力提升</div>
        </div>
      </div>
    </div>
    <div class="title" style="margin-top: 100px">定制化服务</div>
    <div class="list3 list">
      <div class="list3-item">
        <img class="layIcon" src="@/style/image/teachIng/img1.png">
        <div class="item-title">教学规划</div>
        <div class="item-line" />
        <div class="item-text">
          根据最新的教学大纲，规划教学制度，实现定制化学习。 
          <!-- sdsad -->
        </div>
      </div>
      <div class="list3-item">
        <img class="layIcon" src="@/style/image/teachIng/img2.png">
        <div class="item-title">教学内容</div>
        <div class="item-line" />
        <div class="item-text">
          科学划分基础、强化、冲刺三个备考阶段并配套课后习题巩固考点，及时检测，层层递进。
        </div>
      </div>
      <div class="list3-item">
        <img class="layIcon" src="@/style/image/teachIng/img3.png">
        <div class="item-title">测评环节</div>
        <div class="item-line" />
        <div class="item-text">随堂测试、课程模考专属考场分梯度进行测试</div>
      </div>
      <div class="list3-item">
        <img class="layIcon" src="@/style/image/teachIng/img4.png">
        <div class="item-title">专属服务</div>
        <div class="item-line" />
        <div class="item-text">
          配备专属教务人员，一对一提供考前答疑、考前提醒等专属类服务。
        </div>
      </div>
    </div>
    <div class="title" style="margin-top: 100px">
      班期制弹性备考 个性化服务学员
    </div>
    <div class="content2" />
    <div class="title" style="margin-top: 100px">面授集训实操 强化学习成效</div>
    <el-carousel
      class="carousel"
      indicator-position="none"
      :interval="4000"
      type="card"
      height="315px"
    >
      <el-carousel-item>
        <img class="layIcon" src="@/style/image/teachIng/teach1.jpg">
      </el-carousel-item>
      <el-carousel-item>
        <img class="layIcon" src="@/style/image/teachIng/teach2.jpg">
      </el-carousel-item>
      <el-carousel-item>
        <img class="layIcon" src="@/style/image/teachIng/teach3.jpg">
      </el-carousel-item>
      <el-carousel-item>
        <img class="layIcon" src="@/style/image/teachIng/teach4.jpg">
      </el-carousel-item>
      <el-carousel-item>
        <img class="layIcon" src="@/style/image/teachIng/teach5.jpg">
      </el-carousel-item>
      <el-carousel-item>
        <img class="layIcon" src="@/style/image/teachIng/teach6.jpg">
      </el-carousel-item>
      <el-carousel-item>
        <img class="layIcon" src="@/style/image/teachIng/teach7.jpg">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  async created() {},
  mounted: {}
}
</script>
<style lang="less" scoped>
.teaching {
  width: 100%;
  background: #fff;
  padding-bottom: 120px;
  /* 标题 */
  .title {
    width: 100%;
    font-size: 40px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
    width: 100%;
    text-align: center;
    margin-top: 60px;
  }
  .content1 {
    width: 100%;
    height: 480px;
    background: url("~@/style/image/teachIng/teachingBg1.png");
    background-size: 100% 100%;
  }
  .content2 {
    width: 1080px;
    margin: 48px auto 0px;
    height: 570px;
    background: url("~@/style/image/teachIng/bigBg1.png");
    background-size: 100% 100%;
  }
  .list {
    width: 1080px;
    display: flex;
    margin: 48px auto 0px;
    justify-content: space-between;
  }
  .list1 {
    .list1-item {
      width: 261px;
      height: 286px;
      background: #ffffff;
      box-shadow: 0px 0px 12px 1px rgba(43, 119, 254, 0.25);
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      padding: 40px 0px 0px 32px;
      .layIcon {
        width: 90px;
        margin: 0px auto;
        height: 90px;
      }
      .item-content {
        margin-top: 20px;
        display: flex;
        .content-left {
          .text {
            font-size: 20px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            line-height: 20px;
            margin-top: 16px;
          }
          .line {
            margin-top: 16px;
            width: 80px;
            height: 8px;
            background: #2b77fe;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
          }
        }
        .content-right {
          margin-left: 32px;
          .text {
            margin-top: 16px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 16px;
            display: flex;
            align-items: center;
            .yuan {
              width: 6px;
              height: 6px;
              background: #1bd6ff;
              opacity: 1;
              border-radius: 50%;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
  .list2 {
    .list2-item {
      width: 520px;
      height: 450px;
      padding: 32px 0px 0px 72px;
      &:nth-child(1) {
        background: url("~@/style/image/teachIng/listBg1.png");
        background-size: 100% 100%;
      }
      &:nth-child(2) {
        background: url("~@/style/image/teachIng/listBg2.png");
        background-size: 100% 100%;
      }
      .item-title {
        font-size: 32px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 32px;
      }
      .content {
        margin-top: 71px;
        .text {
          font-size: 20px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-bottom: 28px;
          line-height: 20px;
        }
      }
    }
  }
  .list3 {
    .list3-item {
      width: 200px;
      .layIcon {
        width: 200px;
        height: 200px;
      }
      .item-title {
        margin-top: 32px;
        font-size: 24px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 24px;
      }
      .item-line {
        width: 40px;
        margin-top: 16px;
        height: 8px;
        background: #2b77fe;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
      .item-text {
        margin-top: 16px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
      }
    }
  }
  .carousel {
    width: 1080px;
    margin: 48px auto 0px;
    .layIcon {
      width: 560px;
      height: 315px;
    }
  }
}
</style>
